"use client";
import Image from "next/image";
import Link from "next/link";

const HeroSection = ({
  image = "/images/hero/web-design-agency-near-me.jpg",
  abovetitle = "North Carolina and Mumbai",
  title = "Let's design a fantastic website through our Web Design Services",
  colouredTitle = "",
  salespitch = "Unlock your company's digital potential with our experienced web design services in North Carolina and Mumbai, where we blend creativity and technology to create engaging, result-driven websites",
  letsGoLink = "/contact-us",
  letsGoText = "Call our Experts",
}) => {
  return (
    <>
      <section className="max-w-screen-xl mx-auto w-full">
        <div className="flex flex-col md:flex-row gap-3 md:gap-6 items-center justify-center md:justify-between">
          <div className="flex-1 flex-shrink-0 order-2 md:order-1 pr-5 md:pr-0 mb-10 mx-5">
            <div className="-z-10">
              <p className="font-semibold uppercase text-neutral-600">
                {abovetitle}
              </p>
              <h1 className="font-bold text-neutral-800 py-2">
                {title}
                <span className="text-color-orange-700"> {colouredTitle}</span>
              </h1>
              <p dangerouslySetInnerHTML={{ __html: salespitch }}></p>
            </div>
            <div className="mt-0 md:mt-6">
              <Link
                href={letsGoLink}
                className="inline-block font-medium md:text-base mt-4 md:mt-4 lg:mt-0 lg:text-lg text-black hover:text-white bg-white hover:bg-yellow-700 border-2 border-sky-800 rounded-full px-10 py-2 md:px-15"
              >
                {letsGoText}
              </Link>
            </div>
          </div>
          <div className="flex-1 self-end order-1 md:order-2 px-0 mx-0 py-10 -z-10">
            <Image
              src={image}
              className="self-center md:self-end"
              alt={`${title} ${colouredTitle}`}
              priority={true}
              width={700}
              height={770}
              quality={75}
              sizes="(max-width: 600px) 100vw, (min-width: 600px) 50vw"
            />
          </div>
        </div>
      </section>
    </>
  );
};

export default HeroSection;
